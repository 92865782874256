import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import base from '../styles/base.module.css';
import main from '../styles/main.module.css';
import theme from '../styles/theme.module.css';

import Layout from '../components/layout';

/**
 * About page component. Parent component.
 * @component
 *
 * @param   {string} location - URL data object
 * @returns {component}       - <Offline location={string} />
 */

const Offline = ({ location }) => (
  <Layout pageTitle="Offline" location={location}>
    <article
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyStart,
        base.w100,
        main.mt60,
        main.mb60,
        main.postLegal,
        main.postSingle,
        main.px60
      )}
    >
      <h1 className={cx(base.mb5, base.tc, theme.colorDark)}>
        Looks like you&apos;re offline.
      </h1>
      <section>
        <p>
          Any page you&apos;ve previously visited is avaiable offline. If you
          hit this page, it means the page you&apos;re looking for hasn&apos;t
          been saved for offline viewing. Check your connection or connect to
          WiFi to view the rest of the site.
        </p>
      </section>
    </article>
  </Layout>
);

Offline.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default Offline;
